import * as d3 from 'd3'
const D3Utils = {
  // Accepts an object containing any combination of top, left, rotate,
  // and scale.
  // Applies the transformations in the correct order.
  transform: function (t) {
    var defaults = {
      top: 0,
      left: 0,
      rotate: 0,
      scale: 1
    };
    t = $.extend({}, defaults, t);

    return 'rotate(' + t.rotate + ') scale(' + t.scale + ') ' +
      'translate(' + t.left + ', ' + t.top + ')';
  },
  // In case if digit has many digit, we'll format it using scientific notation. For instance,
  // '10000000' will be '1e7', '100000000' will be '1e8', '0.0000007' will be '1e-7' so on...
  format_digit_value: function (d) {
    return d.toString().length > 7 ? d3.format(".0e")(d) : d;
  }
}
export default D3Utils