import * as d3 from 'd3'

function TreeMap (selector, options) {
  var self      = this,
      container = d3.select(selector),
      options   = (options || {}),
      data      = {},
      svg,
      root,
      main_group,
      width,
      default_options;

  default_options = {
    color: "#E6EEEF",
    selected_sector_color: "#000000",
    treemap_area: ".treemap-section",
    treemap_selector: selector,
    height: 353,
    tooltip_container: ".treemap-tooltip"
  };

  options = $.extend({}, default_options, options);

  (function initialize () {
    container.selectAll('*').remove();
  })();

  function draw_treemap() {
    // width needs to be calculated after the parent div ".treemap_area" ges loaded on the page
    width = $(options.treemap_selector).width();
    svg = container.append('svg')
      .attr("width", width)
      .attr("height", options.height);
    main_group = svg.append('g');
    root = d3.hierarchy(data).sum(function (d) {
      return d.value ? d.value : 0;
    });

    generate_groups();
  }

  function generate_groups(){
    // Then d3.treemap computes the position of each element of the hierarchy
    d3.treemap()
      .size([width, options.height])
      .padding(2)
      (root);

    // use this information to add rectangles:
    main_group
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("rect")
      .attr('x', function (d) { return d.x0; })
      .attr('y', function (d) { return d.y0; })
      .attr('width', function (d) { return d.x1 - d.x0; })
      .attr('height', function (d) { return d.y1 - d.y0; })
      .style("fill", options.color)
      .style("cursor", "pointer")
      .each(treemap_cell_each);

    // use this information to add labels
    main_group
      .selectAll("foreignObject")
      .data(root.leaves())
      .enter()
      // svg <foreignObject> is used for adding html elements to the svg tag, which is used for adding text to the treemap cell
      .append("foreignObject")
      .attr("x", function (d) { return d.x0 })
      .attr("y", function (d) { return d.y0 })
      .attr("width", function (d) { return d.x1 - d.x0 })
      .attr("height",  function (d) { return d.y1 - d.y0 })
      // clicking treemap cell should sent a request to perform filter
      .on("click", treemap_cell_on_click)
      .attr("font-size", "11px")
      .append('xhtml:div')
      .attr("class", "treemap-text-truncate")
      .text(format_treemap_text);

    // tooltip events on hovering over treemap cell
    main_group.selectAll("foreignObject")
      .on("mouseover", treemap_on_mouseover)
      .on("mousemove", treemap_on_mousemove)
      .on('mouseout', treemap_on_mouseout)
  }

  // the function is applied on each treemap cell
  function treemap_cell_each(d){
    var g = d3.select(this);
    // if the data is equal to the selected sector then add a border to the treemap cell
    if (d.data.id === options.selected_sector) {
      g.style('stroke', options.selected_sector_color)
       .style('stroke-width', 2);
    }
  }

  // incase the width or the height of the treemap cell is less than 30px, then show "..." instead of text
  function format_treemap_text(d) {
    var condition = (Math.min((d.x1 - d.x0), (d.y1 - d.y0)) > 30);

    return condition ? d.value.toFixed(1) +'% '+ treemap_tooltip_name(d.data.name) : "..."
  }

  function treemap_on_mouseover(d) {
    if (d.data.id !== options.selected_sector) {
      $(this).addClass("treemap-cell-hovered");
    }

    show_treemap_tooltip(d);
  }

  function show_treemap_tooltip(d) {
    var tooltip = $(options.tooltip_container);
    // eg. "11.4% Real Estate"
    var content = d.value.toFixed(1) +'% '+ treemap_tooltip_name(d.data.name);

    tooltip.html(content);
    tooltip.show();
  }

  // format the tooltip content based on the name
  // @param name [String]
  // @return [String]
  function treemap_tooltip_name(name) {
    return (name === "UNDEFINED") ? "Undefined Industries" : name;
  }

  // adjust tooltip position
  function treemap_on_mousemove() {
    var tooltip = $(options.tooltip_container);

    tooltip.css({
      top: d3.event.pageY + 20,
      left: d3.event.pageX
    });
  }

  function treemap_on_mouseout() {
    $(this).removeClass("treemap-cell-hovered");
    $(options.tooltip_container).hide();
  }

  // clicking treemap cell, should sent a request to filter the barcodes
  function treemap_cell_on_click(d) {
    var event = new CustomEvent('treemap_cell_click', {
        bubbles: true,
        detail: {
          data: d.data.id,
          is_selected_sector: d.data.id === options.selected_sector
        }
    });
    this.dispatchEvent(event);
  }

  this.data = function (d) {
    if (typeof d === 'undefined') {
      return data;
    } else {
      data = d;
    }
  };

  this.draw = function () {
    $(options.treemap_area).show();
    draw_treemap();
  };

  this.options = function (overrides) {
    if (typeof overrides === "undefined") {
      return options;
    } else {
      $.extend(options, overrides);
      return options;
    }
  };

  this.is_empty = function() {
    return data.length < 1;
  };

  this.resize = function (height) {
    if (!self.is_empty()) {
      // set the new dimensions
      if (height) options.height = height;

      // clear the container and redraw the treemap
      container.selectAll("*").remove();
      draw_treemap();
    }
  };
}

export default TreeMap;