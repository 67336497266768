// Functions relating to animations in the app go here


// Take an element and apply a CSS class with an animation attached to it.
// Once the animation is complete. remove the animation class;
function unhideAndAnimateIn (element, animation) {
  $(element).removeClass('hidden').addClass(animation).one('animationend', function() {
    $(element).removeClass(animation);
  });
}

// Take an element and apply a CSS class with an animation attached to it.
// Once the animation is complete. remove the element;
function animateOutAndRemove (element, animation) {
  $(element).addClass(animation).one('animationend', function() {
    $(element).remove();
  });
}

// Take an element and apply a CSS class with an animation attached to it.
// Once the animation is complete. hide the element;
function animateOutAndHide (element, animation) {
  $(element).addClass(animation).one('animationend', function() {
    // Must to remove 'animation' CSS class, otherwise the element cannot hide
    // properly.
    $(element).removeClass(animation).addClass('hidden');
  });
}

export default { unhideAndAnimateIn, animateOutAndRemove, animateOutAndHide }