/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import {} from 'jquery-migrate'
import Turbolinks from 'turbolinks';
Turbolinks.start()
import {} from 'jquery-ujs';

require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("chart.js")

import ahoy from "ahoy.js"
import Notifly from 'notifly'
import Overlay from 'overlay'
import isElectron from 'isElectron'
import Animations from 'animations'
import LocusGrid, { giveBorderToLabel } from 'locusGrid'
import GeoMap from 'geoMap'
import TreeMap from 'treeMap'
import { startProgressBar, completeProgressBar } from 'progressBar'
import {} from 'estimatedStaffingPatterns'
import {} from 'areas'


ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");

export {
  Notifly,
  Overlay,
  isElectron,
  Animations,
  LocusGrid,
  giveBorderToLabel,
  GeoMap,
  TreeMap,
  startProgressBar,
  completeProgressBar,
  Turbolinks
}