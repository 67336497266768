import * as d3 from 'd3'
import D3Utils from 'd3Utils'

export default function LocusGrid (selector, options) {
  var self       = this,
      container  = d3.select(selector),
      options    = (options || {}),
      data       = [],
      national_data = [],
      scales     = null,
      controls   = null,
      svg, $svg, main_group, max_value, default_options,
      boundary_font_size = 10,
      label_color = '#4A4A4A';

  default_options = {
    scale_by_type: 'relative',
    scale_by_value: 15,
    offset: {x: 130, y: 100},
    activity_level: 1,
    resource_level: 1,
    selected_activity: null,
    selected_resource: null,
    tooltip: "{{activity}} {{resource}}: {{value}}",
    resource_domains: {
      1: ["F","A","B","E","C","D","DIV"]
    },
    activity_domains: {
      4: ["1","2","3","4","DIV"],
      12: ["1.1", "1.2", "1.3", "2.1", "2.2", "2.3",
          "3.1", "3.2", "3.3", "4.1", "4.2", "4.3", "DIV"]
    },
    box_margin: 3,
    box_min: 4,
    labels: {
      font_size: 12,
    },
    description_lists: {
      'enterprise': {
        '1.1F': 'staffing agencies and membership organizations',
        '1.2F': 'passenger transportation service companies',
        '1.3F': 'temporary staffing agencies, prisons, and childcare service companies',
        '2.1F': 'schools and workforce training',
        '2.2F': 'movie theaters, barber shops, and elderly care service companies',
        '2.3F': 'medical diagnostics and healthcare service companies',
        '3.1F': 'talent agents, talent managers, and labor unions',
        '4.1F': 'social work and religious organizations',
        '4.2F': 'labor regulators',
        '1.1A': 'real estate brokers',
        '1.3A': 'hotels, real estate operators, and home security services',
        '2.1A': 'architecture, landscape design, and land surveying',
        '2.2A': 'construction contractors and real estate developers',
        '2.3A': 'locksmiths, building inspectors, and environment disaster assessment agencies',
        '3.1A': 'prefabricated and mobile home sellers and sell-side brokers',
        '3.3A': 'real estate lessors',
        '4.1A': 'environmental conservation organizations and urban planners',
        '4.2A': 'property managers',
        '1.1B': 'supply chain optimization and equipment brokers',
        '1.2B': 'waste collection service companies, furniture movers, and towing service companies',
        '1.3B': 'car and equipment rentals, hazardous waste storage, and cruise ship operators',
        '2.1B': 'fashion design, industrial design, and aerospace parts engineering',
        '2.2B': 'components and final equipment manufacturers and miners',
        '2.3B': 'equipment repair service companies and veterinary hospitals',
        '3.1B': 'department stores, car dealerships, and building materials wholesalers',
        '3.3B': 'car and equipment lessors',
        '4.1B': 'none',
        '4.2B': 'transportation regulators',
        '1.1E': 'nutritionist practices, agricultural and animal products brokers, and electricity brokers',
        '1.2E': 'natural gas pipeline operation, petroleum transportation, and electricity transmission',
        '1.3E': 'grain silo operators',
        '2.1E': 'pharmaceutical R&D and agricultural R&D',
        '2.2E': 'agriculture and food processing, fuel mining and processing, electricity production',
        '2.3E': 'oil and gas inspection companies',
        '3.1E': 'grocery stores, restaurants, and gas stations',
        '4.2E': 'agricultural and utilities regulators',
        '1.1C': 'software and database procurement agencies',
        '1.2C': 'telecom, radio, and mail delivery service companies',
        '1.3C': 'search platforms, media streaming service companies, and libraries',
        '2.1C': 'graphic design',
        '2.2C': 'media production service companies, software producers, and publishers',
        '2.3C': 'software and technical customer support companies',
        '3.1C': 'bookstores and newsstands',
        '3.3C': 'music, movies, and intellectual property licensing',
        '4.1C': 'diplomatic organizations',
        '1.1D': 'securities and insurance brokerages',
        '2.2D': 'currency printing companies',
        '3.1D': 'casinos and grant making organizations',
        '3.2D': 'insurance claims processing, payroll service companies, and transaction processing',
        '3.3D': 'insurance and money lenders',
        '4.1D': 'investment advisors and actuarial researchers',
        '4.2D': 'investment managers and holding companies',
        '4.3D': 'accounting firms',
        '1.1DIV': 'wholesale brokers, travel agents, and media agents',
        '1.2DIV': 'shipping service companies',
        '1.3DIV': 'defense service companies',
        '2.1DIV': 'general research, design, and engineering firms',
        '2.2DIV': 'diversified consumer goods manufacturers and packaging service companies',
        '2.3DIV': 'business inspection service companies, customer service call centers',
        '3.1DIV': 'retail stores and internet retailers',
        '4.1DIV': 'management consulting',
        '4.2DIV': 'local, state, and federal governments',
        '4.3DIV': 'notary firms',

        '1F': 'staffing agencies, passenger transportation service companies, and childcare service companies',
        '1A': 'real estate brokers, and hotels',
        '1B': 'equipment brokers, waste collection service companies, and car rentals',
        '1E': 'electricity brokers, natural gas pipline operation, and grain silo operators',
        '1C': 'software procurement agencies, telecom, and libraries',
        '1D': 'securities and insurance brokerages',
        '1DIV': 'travel agents, shopping service companies, and defense service companies',
        '2F': 'schools, movie theaters, and medical diagnostics',
        '2A': 'architecture designers, construction contractors, and building inspectors',
        '2B': 'fashion designers, equipment manufacturers, and veterinary hospitals',
        '2E': 'pharmaceutical R&D, electricity production, and oil and gas inspection companies',
        '2C': 'graphic design, software produces, technical customer support companies',
        '2DIV': 'general research firms, packaging service companies, and customer service call centers',
        '3F': 'talent agents',
        '3A': 'sell-side real estate brokers and real estate lessors',
        '3B': 'department stores and car lessors',
        '3E': 'grocery stores, restaurants and gas stations',
        '3C': 'bookstores and intellectual property licensing',
        '3D': 'casinos, payroll service companies and insurance companies',
        '3DIV': 'retails stores and internet retailers',
        '4F': 'religious organizations and labor regulators',
        '4A': 'environmental conservation organizations and property managers',
        '4B': 'transportation regulators',
        '4E': 'agricultural and utilities regulators',
        '4C': 'diplomatic organizations',
        '4D': 'investment advisors, holding companies and accounting firms',
        '4DIV': 'management consulting, federal governments and notary firms',
        'DIVDIV': 'diversified personal, business, and government service companies',
        'DIVD': 'diversified banking service companies',
        'DIVC': 'support service companies for media production'
      },
      'labor': {
        '1.1F': 'Labor Contractors and Headhunters',
        '1.2F': 'Police Dispatchers',
        '1.3F': 'Human Protection Workers and Human Resources Workers',
        '2.1F': 'Teachers, Anthropologists, and Psychologists',
        '2.2F': 'Tour Guides, Dancers, and Musicians',
        '2.3F': 'Genetic Counselors, Doctors, and Athletic Trainers',
        '3.1F': 'Talent Agents',
        '4.1F': 'Social Workers, Counselors, and Religious Workers',
        '4.2F': 'Supervisors, Personnel Managers, and Coaches',
        '1.1A': 'Surveyors, Travel Agents, and Buy-Side Real Estate Brokers',
        '1.3A': 'Conservationists and Security Guards',
        '2.1A': 'Mining Engineers, Civil Engineers, and Architects',
        '2.2A': 'Construction Workers, Oil Well Drillers, and Pavers',
        '2.3A': 'Building Inspectors, Plumbers, and Electricians',
        '3.1A': 'Title Examiners, Real Estate Brokers, and Hotel Desk Clerks',
        '4.1A': 'Urban Planners',
        '4.2A': 'Lodging Managers and Parking Enforcement Workers',
        '1.1B': 'Materials Purchasing Agents, and Retail Buyers',
        '1.2B': 'Log Movers and Material Loaders',
        '1.3B': 'Animal Control Workers and Storage Workers',
        '2.1B': 'Materials Scientists, Clothing Designers, and Mechanical Engineers',
        '2.2B': 'Boilermakers, Riggers, and Equipment Assemblers',
        '2.3B': 'Aircraft Mechanics, Automotive Repair Workers, and Plumbers',
        '3.1B': 'Clothing Advertisers, Retail Workers, and Parts Salespersons',
        '4.1B': '',
        '4.2B': '',
        '1.1E': 'Food Procurement, Dieticians, and Nutritionists',
        '1.2E': 'Oil Pump Operators',
        '1.3E': 'Fish and Game Wardens, Pharmacy Aides',
        '2.1E': 'Agricultural Researchers',
        '2.2E': 'Meat Packers, Bakers, and Petroleum Pump System Operators',
        '2.3E': 'Doctors, Nurses, and Diagnosticians',
        '3.1E': 'Food Salespeople and Gas Station Attendants',
        '4.2E': '',
        '4.3E': 'Agricultural Inspectors',
        '1.1C': 'Software Procurement',
        '1.2C': 'Mail Carriers',
        '1.3C': 'Information Security Analysts and Librarians',
        '2.1C': 'Computer Systems Analysts, Film Directors, and User Interface Designers',
        '2.2C': 'Software Designers, Editors, and Photographers',
        '2.3C': 'Art Refurbishers',
        '3.1C': 'Securities Sales Agents',
        '1.1D': 'Fundraisers and Securities Brokers',
        '2.1D': 'Lottery Game Designers',
        '2.2D': 'Currency Printing Machine Operators',
        '3.1D': 'Loan Interviewers, Credit Analysts, and Insurance Sales Agents',
        '3.2D': 'Appraisers, Payroll Workers, and Accountants',
        '3.3D': 'Insurance Underwriters, Loan Officers, and Tax Preparers',
        '4.1D': 'Financial Analysts, Credit Counselors, and Personal Financial Advisors',
        '4.2D': 'Financial Managers and Chief Financial Officer',
        '4.3D': 'Accountants and Financial Examiners',
        '1.1DIV': 'Procurement Consultants, Order Clerks, and Travel Guides',
        '1.2DIV': 'Diversified Material Movers and Shipping Clerks',
        '1.3DIV': 'Inventory Workers and Diversified Storage Workers',
        '2.1DIV': 'General Engineers, Scientists and Researchers, and Designers',
        '2.2DIV': 'Machine Setters, Packaging Machine Operators, and Casters',
        '2.3DIV': 'Customer Service Representatives, and Diversified Repair Workers',
        '3.1DIV': 'Market Researchers, Cost Estimators, and Advertisers',
        '4.1DIV': 'Operations Research Analysts, Management Analysts, and Legislators',
        '4.2DIV': 'Managers, Superintendents, and Lawyers',
        '4.3DIV': 'Compliance Officers',

        '1F': 'Recruiters, Human Resources Workers, and Human Protection Workers',
        '1A': 'Surveyors, Conservationists, and Travel Agents',
        '1B': 'Materials Purchasing Agents, Materials Movers, and Materials Inventory Workers',
        '1E': 'Food Procurement, Fish and Game Wardens, and Oil Pump Operators',
        '1C': 'Mail Carriers, Librarians, and Information Security Analysts',
        '1D': 'Fundraisers, Securities Brokers, and Armored Truck Drivers',
        '1DIV': 'Fundraisers, Securities Brokers, and Armored Truck Drivers',
        '2F': 'Teachers, Musicians, and Actors',
        '2A': 'Architects, Construction Workers, and Building Inspectors',
        '2B': 'Materials Scientists, Equipment Assemblers, and Automotive Repair Workers',
        '2E': 'Meat Packers, Bakers, and Petroleum Pump System Operators',
        '2C': 'Computer Systems Analysts, Software Designers, and Editors',
        '2DIV': 'General Engineers, Machine Setters, and Diversified Repair Workers',
        '3F': 'Artists\' Agents and Personal Public Relations Specialists',
        '3A': 'Real Estate Brokers, Title Examiners, and Hotel Desk Clerks',
        '3B': 'Clothing Advertisers, Retail Workers, and Parts Salespersons',
        '3E': 'Gas Station Attendants, and Grocery Store Cashiers',
        '3C': 'Movie and Book Salespeople, and Ticket Salespeople',
        '3D': 'Loan Interviewers, Insurance Underwriters, and Payroll Workers',
        '3DIV': 'Market Researchers, Cost Estimators, and Advertisers',
        '4F': 'Supervisors, Social Workers, and Coaches',
        '4A': 'Urban Planners and Lodging Managers',
        '4E': 'Agricultural Inspectors',
        '4D': 'Financial Analysts, Credit Counselors, and Financial Managers',
        '4DIV': 'Managers, Management Consultants, and Management Analysts'
      }
    },
    entity_count_decimal_format: 2,
    agg_method_disable_national_percentage: ["average_sales", "average_number_of_employees"]
  }

  options = $.extend({}, default_options, options);

  (function initialize () {
    container.selectAll('*').remove();
    svg        = container.append('svg').classed('locus-grid', true);
    $svg       = $(svg.node());
    main_group = svg.append('g');


    svg.attr('preserveAspectRatio', 'none');

    main_group.attr('transform', D3Utils.transform({
      left: options.offset.x, top: 0
    }));
  })();

  function get_axis_ticks (axis_selector, pattern, match) {
    var values = [],
        matches;
    main_group.selectAll(axis_selector + ' .tick').each(function (d, i) {
      if (typeof match === 'undefined' || d == match) {
        // Solve issue in Edge and IE, `d3.select(this).attr('transform')` will return the following
        // `translate(translateX) if TranslateY is 0` or `translate(translateX translateY)`,
        // while chrome/Firefox return `translate(translateX,translateY)` for all above cases.
        var translate = d3.select(this).attr('transform').split(/[ ,]+/) // split by space (Edge/IE) or comma (Chrome/Firefox)
        var translateX = translate[0].replace(/[^0-9\-.,]/g, '') // extract integer values
        var translateY = translate.length > 1 ? translate[1].replace(/[^0-9\-.,]/g, '') : 0 // if translateY exist, get value; otw, return 0
        matches = pattern.exec("translate(" + translateX + "," + translateY + ")");
        if (matches) { values.push(matches[2]); }
      }
    });
    return values;
  }

  function color_class (code) {
    return 'locus-' + code.replace(/\./g, '_');
  }

  function get_x_ticks (match) {
    return get_axis_ticks('.x.axis', /(\()(\d+\.?\d*)(\,)/g, match);
  }

  function get_y_ticks (match) {
    return get_axis_ticks('.y.axis', /(\,)(\d+\.?\d*)(\))/g, match);
  }

  function get_max_box_size(){
    var xroom, yroom,
        xticks = get_x_ticks(),
        yticks = get_y_ticks();

    xroom = xticks.length == 1 ? $svg.width() : xticks[1] - xticks[0];
    yroom = yticks.length == 1 ? $svg.height() : yticks[1] - yticks[0];
    return Math.min(xroom, yroom) / 2 - options.box_margin;
  }

  function box_size (max, datum) {
    var size = datum.count / get_max_value(max),
        maxv;
    maxv = get_max_box_size()
    // We should have a minimum unless it's zero then it should be zero.
    return datum.count != 0 ? Math.max(Math.sqrt(size) * maxv, options.box_min) : 0;
  }

  function get_max_value(max) {
    return options.scale_by_type === 'percentage' ? options.entity_count * options.scale_by_value/100 : max;
  }

  function draw_controls () {
    controls = main_group.append('g')
    var circle_offset,
        toggle_width = 25,
        toggle_height = 15,
        rect;
    if (options.activity_level == '4') {
      circle_offset = toggle_height/2;
    } else {
      circle_offset = toggle_width - (toggle_height/2);
    }

    controls.attr('transform', D3Utils.transform({left: -(options.offset.x) + 12, top: 20}))
      .classed('activity-format-toggle', true)
      .on('click', function () {
        $(this).trigger('activity_toggle');
      });

    controls.append('rect')
      .attr('width', toggle_width+'px')
      .attr('height', toggle_height+'px')
      .style('fill', label_color)
      .attr('rx', (toggle_height/2)+'px')
      .attr('ry', (toggle_width/2)+'px')

    controls.append('circle')
      .style('fill', 'white')
      .style('stroke', label_color)
      .attr('r', (toggle_height/2)+'px')
      .attr('transform', D3Utils.transform({ left: circle_offset, top: (toggle_height/2) }))

    controls.append('text')
      .text('4')
      .attr('text-anchor', 'middle')
      .attr('transform', D3Utils.transform({ left: -8, top: 13 }))
      .style('fill', label_color)
      .style('font-size', '12px')
    controls.append('text')
      .text('12')
      .attr('text-anchor', 'middle')
      .attr('transform', D3Utils.transform({ left: (toggle_width+10), top: 13 }))
      .style('fill', label_color)
      .style('font-size', '12px')
  }

  function draw_axes () {
    var width   = $svg.width() - options.offset.x,
        height  = $svg.height() - options.offset.y,
        activity_domain = options.activity_domains[options.activity_level],
        resource_domain = options.resource_domains[options.resource_level],
        x_axis, y_axis;

    scales = {
      x: d3.scalePoint()
         .domain(resource_domain)
         .range([0, width])
         .padding(resource_domain.length / 2),
      y: d3.scalePoint()
         .domain(activity_domain)
         .range([0, height])
         .padding(activity_domain.length / 2),
    };

    x_axis  = d3.axisTop().scale(scales.x)
              .tickSize(-height, 2)
              .tickPadding(10)
              .tickFormat(function () { return '' }),
    y_axis  = d3.axisLeft().scale(scales.y)
              .tickSize(-width, 2)
              .tickPadding(10)
              .tickFormat(function () { return '' });

    main_group.append('g').classed('x axis', true).call(x_axis);
    main_group.append('g').classed('y axis', true).call(y_axis);

    create_labels();
  }

  function create_labels () {
    var resource_y_target = $svg.height() - options.offset.y + 25;
    main_group.append('g')
      .attr('transform', D3Utils.transform({top: resource_y_target}) )
      .append('text')
      .text('Resource')
      .classed('resource-label', true)
      .attr('text-anchor', 'end')
      .attr('font-size', 16)
      .style("fill", label_color)
      .attr('transform', D3Utils.transform({left: 20, top: -5, rotate: -60} ));

    main_group.append('text')
      .text('Activity')
      .classed('activity-label', true)
      .attr('text-anchor', 'end')
      .attr('font-size', 16)
      .style("fill", label_color)
      .attr('transform', D3Utils.transform({ left: -30, top: 10 }));

    var x_axis = main_group.selectAll('.x.axis')
        .data(scales.x.domain(), function (d,i) { return d + i })
        .enter().append('g')
        .attr('font-size', options.labels.font_size)
        .attr('activity_resource_val', function(d) { return "ANY_" + d })
        .attr('transform', function (d) {
          return D3Utils.transform({ left: scales.x(d), top: resource_y_target });
        }).on('click', function (d) {
          var event = new CustomEvent('grid_label_click', {
            bubbles: true,
            detail: {
              label: d,
              axis: 'x',
              type: 'resource',
              container_num:  $(this).parents('.area-container').data('container-number')
            }
          });
          this.dispatchEvent(event);
        });

    x_axis.append('text')
      .text(function (d) { return options.code_labels[d]; })
      .style("fill", label_color)
      .attr('transform', D3Utils.transform({ rotate: -60 }))
      .attr('text-anchor', 'end')
      .classed('resource-tick', true);

    var x_axis_circle_group = x_axis.append('g')
                                .attr('transform', D3Utils.transform({top: -5}));

    x_axis_circle_group.append('circle')
      .attr('transform', D3Utils.transform({ top: -10 }))
      .attr('class', color_class)
      .attr('r', 10)
      .style('stroke-width', function(d) {
         if (options.selected_activity === "" && options.selected_resource === d) {
           return 2;
         } else {
           return 0;
         }
        })
      .style('stroke', '#000000')

    x_axis_circle_group.append('text')
      .text(function (d) { return d })
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('cursor', 'default')
      .attr('transform', D3Utils.transform( { top: -5 }))
      .attr('fill', 'white')

    x_axis_circle_group.on('mouseover', resource_axes_on_mouseover)
      .on('mouseout', function (d) {$('.tooltip-axes').hide()});

    var y_axis = main_group.selectAll('.y.axis')
        .data(scales.y.domain(), function (d,i) { return d + i })
        .enter().append('g')
        .attr('font-size', options.labels.font_size)
        .attr('activity_resource_val', function(d) { return  d +"_ANY"})
        .attr('transform', function (d) {
          return D3Utils.transform({ left: -15, top: scales.y(d) + 5 })
        }).on('click', function (d) {
          var event = new CustomEvent('grid_label_click', {
            bubbles: true,
            detail: {
              label: d,
              axis: 'y',
              type: 'activity',
              container_num: $(this).parents('.area-container').data('container-number')
            }
          });
          this.dispatchEvent(event);
        });

    y_axis.append('text')
      .text(function (d) { return options.code_labels[d]; })
      .style("fill", label_color)
      .attr('text-anchor', 'end')
      .attr('transform', D3Utils.transform({ left: -15 }))
      .classed('activity-tick', true);

    var y_axis_circle_group = y_axis.append('g')
                                .attr('transform', D3Utils.transform({top: -5}));

    y_axis_circle_group.append('circle')
      .attr('r', 10)
      .attr('class', color_class)
      .style('stroke-width', function(d) {
          if (options.selected_activity === d && options.selected_resource === "") {
            return 2;
          } else {
            return 0;
          }
        })
      .style('stroke', '#000000');

    y_axis_circle_group.append('text')
      .text(function (d) { return d; })
      .style('font-weight', 'bold')
      .style('cursor', 'default')
      .attr('text-anchor', 'middle')
      .attr('transform', D3Utils.transform( { top: 5 }))
      .attr('fill', 'white')

    y_axis_circle_group.on('mouseover', activity_axes_on_mouseover)
      .on('mouseout', function (d) {$('.tooltip-axes').hide()});
  }

  function data_id (d, prefix) {
    prefix = (prefix || 'b');
    return prefix + selector.replace(/[^a-zA-Z0-9]/g, '_') +
           d.activity.replace(/\./g,'_') + '_' + d.resource;
  }

  function draw_boxes () {
    var drawable_data = $.map(data, function (d, i) {
      return d.activity === null || d.resource === null ? null : d
    });

    var box_group = main_group.selectAll('g.grid-box').data(drawable_data)
        .enter().append('g')
        .classed('grid-box', true)
        .classed('selected', function (d) {
          return options.selected_activity == d.activity &&
                 options.selected_resource == d.resource;
        })
        .style('display', function (d) {
          var has_x = get_x_ticks(d.resource).length > 0,
              has_y = get_y_ticks(d.activity).length > 0;
          // hides boxes that do not exist in the domain
          return has_x && has_y ? '' : 'none';
        })
        .attr('data-size', function (d) {
          return Math.min(box_size(max_value, d), get_max_box_size())
        })
        .attr('transform', function (d) {
          var half = $(this).data('size') / 2;
          return D3Utils.transform({ top: -half, left: -half });
        })
        .each(function (d) {
          // set each attribute onto the group, may not be needed, but convenient
          // for click events and such
          for (var i in d) { d3.select(this).attr(i, d[i]); }
        }),
        defs   = box_group.append('defs'),
        filter = defs.append('filter')
          .attr('id', function (d) { return data_id(d, 'f') })
          .attr('height', '130%');

    filter.append('feColorMatrix')
      .attr('in', 'SourceAlpha')
      .attr('type', 'matrix')
      // the homogenious column is the rgba values
      .attr('values', '1 0 0 0 0.5  0 1 0 0 0.5  0 0 1 0 0.5  0 0 0 1 0')
      .attr('result', 'colorBlur');
    filter.append('feOffset')
      .attr('in', 'colorBlur')
      .attr('dx', -2)
      .attr('dy', 4)
      .attr('result', 'offset');
    filter.append('feGaussianBlur')
      .attr('in', 'offset')
      .attr('stdDeviation', 2)
      .attr('result', 'offsetBlur');

    var feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    defs.append('clipPath')
       .attr('id', function (d) { return data_id(d) })
       .append('rect')
       .attr('x', function (d) { return scales.x(d.resource) })
       .attr('y', function (d) { return scales.y(d.activity) })
       .attr('rx', 3).attr('ry', 3)
       .attr('width', function (d) {return $(this).closest('.grid-box').data('size');})
       .attr('height', function (d) {return $(this).closest('.grid-box').data('size');});

    box_group.append('rect')
      .attr('x', function (d) { return get_x_ticks(d.resource)[0]; })
      .attr('y', function (d) { return get_y_ticks(d.activity)[0]; })
      .attr('width', function (d) { return $(this).parent().data('size'); })
      .attr('height', function (d) { return $(this).parent().data('size'); })
      .attr('class', function (d) { return color_class(d.resource); })
      .classed('resource', true)
      .attr('clip-path', function (d) {
        return 'url(#'+data_id(d)+')';
      });

    box_group.append('polygon')
      .attr('class', function (d) { return color_class(d.activity); })
      .classed('activity', true)
      .attr('clip-path', function (d) {
        return 'url(#'+ data_id(d) +')';
      })
      .attr('points', function (d) {
        var size = parseFloat($(this).parent().data('size')),
            eps  = 0.3,
            x    = parseFloat(get_x_ticks(d.resource)[0]) - eps,
            y    = parseFloat(get_y_ticks(d.activity)[0]);
        return x+','+(y+size)+' '+x+','+y+' '+(x+size)+','+y;
      });

    box_group.append('rect')
      .attr('x', function (d) { return get_x_ticks(d.resource)[0] - 0.5; })
      .attr('y', function (d) { return get_y_ticks(d.activity)[0] - 0.5; })
      .attr('width', function (d) { return $(this).parent().data('size'); })
      .attr('height', function (d) { return $(this).parent().data('size'); })
      .attr('class', function (d) {
        return exceeds_size(d)
      })
      .classed('mask', true)
      .classed('border', function (d) {
        return d3.select(this.parentNode).classed('selected');
      })
      .attr('clip-path', function (d) {
        return 'url(#' + data_id(d) + ')';
      });

    box_group.attr('filter', function (d) {
      if (d3.select(this).classed('selected')) {
        return 'url(#'+data_id(d, 'f')+')';
      }
      return null;
    }).on('mouseover', box_group_on_mouseover)
      .on('mouseout', function (d) {
      $('.tooltip-grid').hide();
    }).on('click', function (d) {
      d["container_num"] = $(this).parents('.area-container').data('container-number');
      d["box_group"] = box_group;
      var event = new CustomEvent('grid_box_click', {
        bubbles: true,
        detail: d
      });
      this.dispatchEvent(event);
    });
  }

  // event handler for resource axis (x axis) when mouseover
  function resource_axes_on_mouseover (d) {
    if (!window.grids) { return; } // if grids is undefined, return
    // get grid id
    var grid_id = get_grid_id($(this));
    var grid = window.grids[grid_id];
    show_axes_tooltip(d, false, grid, $(this));
  }

  // event handler for activity axis (y axis) when mouseover
  function activity_axes_on_mouseover (d) {
    if (!window.grids) { return; } // if grids is undefined, return
    var grid_id = get_grid_id($(this));
    var grid = window.grids[grid_id];
    show_axes_tooltip(d, true, grid, $(this));
  }

  // get grid_id
  function get_grid_id(current_context) {
    var result = 0;
    if (current_context.closest('div')) {
      var current_context_id = current_context.closest('div').attr('id');
      if (current_context_id !== undefined) {
        result = current_context_id.replace("grid_container_", "");
      }
    }
    return result;
  }

  function show_axes_tooltip(d, is_activity, grid, current_context) {
    var tooltip = $('.tooltip-axes'),
      counts    = calculate_entity_and_national_count(grid, is_activity, d),
      color     = color_class(d).replace("DIV", "Div"),
      text      = grid.options().code_labels[d],

      tooltip_axes_html = grid.options().tooltip_axes,
      radius            = 10,
      offset            = current_context.offset();

    var axes_data = $.grep(grid.data(), function (d) {
      if (is_activity) {
        return d.resource === null;
      } else {
        return d.activity === null;
      }
    });

    // counts["entity_count"] can be undefined!
    if (counts["entity_count"] !== undefined) {
      var national_avg_html = construct_national_avg_text(counts["national_count"], entity_count(axes_data, 'national'), counts["entity_count"], entity_count(axes_data, 'count'), grid.options());
      var missing_stuff_html = construct_missing_count_text($.grep(axes_data, function(ad) {
        if(is_activity) {
            return ad.activity === d
          } else {
            return ad.resource === d
          }})[0]);

      tooltip_axes_html = tooltip_axes_html
        .replace(/\{\{color\}\}/g, color)
        .replace(/\{\{title_text\}\}/g, text)
        .replace(/\{\{entity_count\}\}/g, number_with_commas(counts["entity_count"].toFixed(grid.options().entity_count_decimal_format)))
        .replace(/\{\{national_avg_div\}\}/g, national_avg_html)
        .replace(/\{\{entity_count_description\}\}/g, grid.options().entity_count_description)
        .replace(/\{\{missing_stuff\}\}/g, missing_stuff_html);

      var e_count = entity_count(axes_data, 'count');
      if (axes_data.length === 0) {
        e_count = grid.options().entity_count;
      }

      if (e_count === 0) {
        tooltip_axes_html = tooltip_axes_html
          .replace(/\{\{entity_percentage\}\}/g, "");
      } else {
        tooltip_axes_html = tooltip_axes_html
          .replace(/\{\{entity_percentage\}\}/g, "(" + show_decimals(counts["entity_count"] / e_count * 100, 2) + "%)");
      }
      tooltip.html(tooltip_axes_html);

      var left_position = offset.left + radius;
      var top_position = offset.top + (radius * 2) + 2;
      tooltip.css({
        top: top_position,
        left: left_position,
        display: 'flex'
      });

      check_tooltip_position(tooltip, left_position, top_position);
    }
  }

  function entity_count (data, attribute) {
    var sum = 0.0;
    $.each(data, function (i, d) {
      sum += (d["activity"] === null && d["resource"] === null) || parseFloat(d[attribute]) === null ? 0.0 : parseFloat(d[attribute]);
    });
    return sum
  }
  // event handler for box_group (grid cell) when mouseover
  function box_group_on_mouseover (d) {
    var tooltip = $('.tooltip-grid'),
      color_activity = color_class(d.activity).replace("DIV", "Div"),
      color_resource = color_class(d.resource).replace("DIV", "Div"),
      text_activity          = options.code_labels[d.activity],
      text_resource          = options.code_labels[d.resource],
      entity_explanation     = options.description_lists[options.barcode_type][d.activity + d.resource],
      tooltip_grid_html      = options.tooltip_grid,
      box_size  = $(this).data('size'),
      offset    = $(this).offset();

    var grid_data = $.grep(self.data(), function (d) {
      return d.activity !== null && d.resource !== null;
    });

    var count = parseFloat(d.count);
    var national_avg_html = construct_national_avg_text(d.national, entity_count(grid_data, 'national'), count, entity_count(grid_data, 'count'), options);

    var missing_stuff_html = construct_missing_count_text(d);
    var formatted_num = number_with_commas(count.toFixed(options.entity_count_decimal_format));
    tooltip_grid_html = tooltip_grid_html
                         .replace(/\{\{activity_color\}\}/g, color_activity)
                         .replace(/\{\{resource_color\}\}/g, color_resource)
                         .replace(/\{\{activity_text\}\}/g, text_activity)
                         .replace(/\{\{resource_text\}\}/g, text_resource)
                         .replace(/\{\{entity_count\}\}/g, formatted_num)
                         .replace(/\{\{explanation\}\}/g, entity_explanation)
                         .replace(/\{\{national_avg_div\}\}/g, national_avg_html)
                         .replace(/\{\{barcode_type_string_rep\}\}/g, options.barcode_type == 'enterprise' ? 'Business' : 'Job')
                         .replace(/\{\{entity_count_description\}\}/g, options.entity_count_description)
                         .replace(/\{\{missing_stuff\}\}/g, missing_stuff_html);

    if (options.entity_count !== 0) {
      tooltip_grid_html = tooltip_grid_html
        .replace(/\{\{entity_percentage\}\}/g, show_decimals(count/entity_count(grid_data, 'count') * 100, 2))
    }

    tooltip.html(tooltip_grid_html);
    var left_position = offset.left + (box_size/2);
    var top_position = offset.top + box_size + 2;
    tooltip.css({
      top: top_position,
      left: left_position,
      display: 'flex'
    });

    check_tooltip_position(tooltip, left_position, top_position);
  }

  // calculate company count and national count
  // basically from data, we filter based on either activity or resource
  // then sum the count
  function calculate_entity_and_national_count(grid, is_activity, d) {
    var result = {entity_count: 0, national_count: 0};
    var entity_filter = [];
    var axes_data = $.grep(grid.data(), function (current_data) {
      if (is_activity){
        return current_data.activity === d && current_data.resource === null;
      } else {
        return current_data.resource === d && current_data.activity === null;
      }
    })[0];

    // it is possible to have null axis data! after all those sum
    // for example, you hover over marker div with act: 1
    if (axes_data == undefined) {
      result.entity_count = undefined;
    } else {
      result.entity_count = parseFloat(axes_data.count);
      if (grid.options().national_entity_count > 0) {
        result.national_count = parseFloat(axes_data.national);
      }
    }

    return result;
  }

  // 1) constructing national avg text
  // 2) showing the arrow up or down
  function construct_national_avg_text(national_count, total_national_count, area_count, total_count, options) {
    var result = '';
    if (!options.agg_method_disable_national_percentage.includes(options.agg_method) && national_count && total_count != 0 && total_national_count != 0) {
      result = options.tooltip_nat_avg;
      var national_avg = national_count/total_national_count * 100;
      var area_avg = area_count/total_count * 100;
      var national_avg_diff = ((area_avg - national_avg) / national_avg) * 100;
      var comparison = show_decimals(national_avg_diff, 2);
      if (comparison < 0) {
        result = result
                   .replace(/\{\{national_avg\}\}/g, Math.abs(comparison) + "% lower")
                   .replace(/\{\{arrow\}\}/g, options.tooltip_arrow_down)
      } else {
        result = result
                   .replace(/\{\{national_avg\}\}/g, Math.abs(comparison) + "% higher")
                   .replace(/\{\{arrow\}\}/g, options.tooltip_arrow_up)
      }
    }
    return result;
  }

  function construct_missing_count_text(d) {
    var auxiliary_lines = $.grep(options.agg_method_auxiliary, function (o) {
      return o.agg_method === options.agg_method;
    });
    var result = "";
    if (auxiliary_lines.length > 0) {
      var auxiliary_line = auxiliary_lines[0];

      var missing = d[auxiliary_line.auxiliary];
      if (missing) { // if d doesn't have auxiliary, then return empty string
        var available = d.entity_count - missing;
        var total = d.entity_count;
        if (missing > 0) {
          result = options.missing_text_format;
          var business_text = d.entity_count == 1 ? options.words.business.singular : options.words.business.plural;
          var title = options.agg_method_in_english;
          var activity_resource_text = locus_code(options.code_labels[d.activity], options.code_labels[d.resource]);

          result = result
            .replace(/\{\{available\}\}/g, number_with_commas(available))
            .replace(/\{\{total\}\}/g, number_with_commas(total))
            .replace(/\{\{pluralize_business\}\}/g, business_text)
            .replace(/\{\{title\}\}/g, title)
            .replace(/\{\{activity_resource_type\}\}/g, activity_resource_text);
        }
      }
    }
    return result;
  }

  function locus_code(activity_label, resource_label) {
    var result = "";

    if (activity_label) {
      result = activity_label + " ";
    }

    if (resource_label) {
      result += resource_label;
    }

    return result;
  }

  function check_tooltip_position(tooltip, left_offset, top_offset) {
    if (is_tooltip_div_offscreen_horizontally(tooltip)) {
      left_offset = left_offset - (tooltip.outerWidth() - 80);
      set_tooltip_top_left(tooltip, left_offset, top_offset);
    }
    if (is_tooltip_div_offscreen_vertically(tooltip)) {
      top_offset = top_offset - tooltip.outerHeight() - 25;
      set_tooltip_top_left(tooltip, left_offset, top_offset);
    }
  }

  function is_tooltip_div_offscreen_horizontally(tooltip) {
    var offset = tooltip.offset(),
      width  = tooltip.outerWidth(),
      docW   = $(window).width();

    return (offset.left + width) > (docW);
  }

  //http://stackoverflow.com/questions/1725508/how-can-i-determine-if-an-html-element-is-offscreen
  function is_tooltip_div_offscreen_vertically(tooltip) {
    var offset = tooltip.offset(),
      height = tooltip.outerHeight(),
      docH   = $(window).height(),
      extra_margin = 20;

    return (offset.top + height) > (docH - extra_margin);
  }

  function set_tooltip_top_left(tooltip, left_offset, top_offset) {
    tooltip.css({
      left: left_offset,
      top: top_offset
    });
  }

  // combining national data (country data count) with the data
  // adding national into current data aggregation
  // called when options({national_data: ... })
  function merge_data_with_country_data (data, national_data) {
    data.forEach(function (datum) {
      var national = $.grep(national_data, function (current_national_datum) {
        return current_national_datum.activity === datum.activity && current_national_datum.resource === datum.resource;
      });
      if (national.length > 0) {
        datum["national"] = national[0].count;
      }
    });
  }

  function draw_boundary_wrappers (locus_grid, scales, max_count) {
    var margin = 2;
    let boundary_points = get_three_boundary_wrapper_points(scales, margin);
    draw_boundaries(locus_grid, boundary_points, max_count);
  }

  function get_three_boundary_wrapper_points(scales, margin) {
    var points_dict = {};
    var lbl_height = parseInt($(".resource-label:first").attr("font-size"));

    var bottom_activity = scales.y('4.3') ? scales.y('4.3') : scales.y('4'),
        div_activity = scales.y('DIV'),
        div_resource = scales.x('DIV');

    // distance between two adjacent resources/activities
    var x_distance = (div_resource - scales.x('D')) / 2,
        y_distance = scales.y('4.3') ? (div_activity - scales.y('4.3'))/2 : (div_activity - bottom_activity)/2

    var top = y_distance,
        bottom = div_activity + y_distance - margin,
        right= div_resource + x_distance - margin;

    var lbl_x_center = right + (lbl_height/2) - 3;

    var h_sep1 = y_distance + bottom_activity,
        h_sep2 = (h_sep1 + y_distance)/2,
        v_sep = -x_distance + scales.x('B');

    points_dict.local_services = {class: 'local-services',
                                  label: 'Local Services',
                                  tooltip: 'services',
                                  lookups: [],
                                  label_points: [
                                    right+','+bottom,
                                    (right + lbl_height)+','+bottom,
                                    (right + lbl_height)+','+(h_sep1 + margin),
                                    right+','+(h_sep1 + margin),
                                  ],
                                  text_width:bottom- (h_sep1 + margin),
                                  label_text_x: lbl_x_center,
                                  label_text_y: (h_sep1 + margin) + ((bottom - (h_sep1 + margin))/2) ,
                                  points: ['0,'+top, '0,'+bottom,
                                           right+','+bottom,
                                           right+','+(h_sep1 + margin),
                                           (v_sep - margin)+','+(h_sep1 + margin),
                                           (v_sep - margin)+','+top]};

    points_dict.prod_and_supply = {class: 'prod-and-supply',
                                   tooltip: 'production_and_supply',
                                   label: 'Production and Supply',
                                   lookups: [{term: 'procure', code: 1}, {term: 'produce', code: 2}],
                                   label_points: [
                                     right+',0',
                                     (right + lbl_height)+',0',
                                     (right + lbl_height)+','+(h_sep2 - margin),
                                     right+','+(h_sep2 -margin),
                                   ],
                                   text_width: (h_sep2 - margin),
                                   label_text_x: lbl_x_center,
                                   label_text_y: margin + ((h_sep2 + y_distance - margin)/2),
                                   points: [(v_sep + margin)+','+top,
                                            (v_sep + margin)+','+(h_sep2 - margin),
                                            right+','+(h_sep2 - margin), right+','+top]};

  // keep the middle polygon math from going nanner sauce
  var com_tp = (h_sep2 + margin),     // top
      com_bt = (h_sep1 - margin),     // bottom
      com_lr = (right + lbl_height);  // label right

    points_dict.comm_and_banking = {class: 'comm-and-banking',
                                    tooltip: 'commerce_and_banking',
                                    label: 'Commerce and Banking',
                                    lookups: [{term: 'exchange', code: 3}, {term: 'manage', code: 4}],
                                    label_points: [
                                      right+','+com_tp,
                                      com_lr+','+com_tp,
                                      com_lr+','+com_bt,
                                      right+','+com_bt
                                    ],
                                    label_text_x: lbl_x_center,
                                    text_width: com_bt-com_tp,
                                    label_text_y: com_tp + (com_bt-com_tp)/2,
                                    points: [(v_sep + margin)+','+com_tp,
                                             (v_sep + margin)+','+com_bt,
                                             right+','+com_bt,
                                             right+','+com_tp]};

    return points_dict;
  }

  function draw_boundaries(locus_grid, boundary_points, max_count) {
    var group = locus_grid.append('g')
    for (var key in boundary_points) {
      // boundary outline
      group.append('polygon')
        .attr('class', boundary_points[key].class)
        .style('fill', 'transparent')
        .attr('stroke-width', 1)
        .attr('stroke', '#9B9B9B')
        .attr('points', boundary_points[key].points);

      append_label_to(group, boundary_points[key], max_count);
    }
  }

  function append_label_to(group, boundary_point, max_count){
    if(boundary_point.label){
      group.append("text")
        .style("fill", label_color)
        .attr('transform', 'translate('+(boundary_point.label_text_x + 6) +','+boundary_point.label_text_y+') rotate(-90)')
        .attr("text-anchor", "middle")
        .attr("font-size", boundary_font_size)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .text(ellipsis_label(boundary_point));
    }
  }

  // simulate svg ellipsis
  function ellipsis_label(boundary_point){
    var padding = boundary_font_size/1.85,        // pixels on either side of text as a boundary to trigger ellipsis -
        text_length_requirement = boundary_point.label.length * padding,
        ellipsis = "...",
        reduced_text = boundary_point.label.split(' ')[0] + ellipsis,
        reduced_length_requirement = reduced_text.length * padding;

    if (boundary_point.text_width > text_length_requirement){
      return boundary_point.label;
    } else if (boundary_point.text_width > reduced_length_requirement) {
      return reduced_text
    } else {
      return boundary_point.label[0] + ellipsis;
    }
  }

  function exceeds_size(d){
    return box_size(max_value, d) > get_max_box_size() ? 'exceeds-max' : ''
  }

  this.draw = function (full_draw) {
    self.empty();
    if (full_draw) {
      main_group.selectAll('*').remove();
      scales = null;
      controls = null;
    }

    if (!scales) { draw_axes(); }
    if (!controls) { draw_controls(); }

    // NOTE: order matters here: boxes should be drawn
    //       the last to enable tooltips
    draw_boundary_wrappers(main_group, scales, max_value);
    draw_boxes();
  }

  this.empty = function () {
    main_group.selectAll('.grid-box').remove();
  }

  this.data = function (d) {
    if (typeof d === 'undefined') {
      return data;
    } else {
      data = d;
      max_value = Math.max.apply(null, data.map(function (d) {
        return d.activity !== null && d.resource !== null ? parseFloat(d.count) : null;
      }));
    }
  }

  this.entity_count = function(data, attribute){
    // remove the records having activity || resource as null
    var grid_data = $.grep(data, function (d) {
      return d.activity !== null && d.resource !== null;
    });

    var sum = 0.0;
    $.each(grid_data, function (i, d) {
      sum += parseFloat(d[attribute]) === null ? 0.0 : parseFloat(d[attribute]);
    });
    return sum;
  };

  this.options = function (overrides) {
    if (typeof overrides === 'undefined') {
      return options;
    } else {
      var act_lvl_change = overrides.activity_level && overrides.activity_level != options.activity_level,
        res_lvl_change = overrides.resource_level && overrides.resource_level != options.resource_level,
        national_data_input = overrides.national_data;

      if (act_lvl_change || res_lvl_change) {
        // need to perform a redraw on the axis
        main_group.selectAll('*').remove();
        scales = null
        controls = null
      }

      if (national_data_input) {
        merge_data_with_country_data(data, national_data_input);
      }

      $.extend(options, overrides);
    }
  }
}

let resizing = false

$(window).on('resize', function () {
  if (resizing) {
    clearTimeout(resizing);
  }

  resizing = setTimeout(function () {
    if (typeof grids !== 'undefined') {
      for (let grid in grids) {
        grids[grid].draw(true);
      }
    }

    if (typeof area_grid !== 'undefined') {
      area_grid.draw(true);
    }

    resizing = false;
  }, 250)
});

export function giveBorderToLabel(container_num, selected_activity, selected_product){
  // removes any previously selected label
  let selected_label = $('#grid_container_'+container_num).find(".selected-label");
  selected_label.removeClass('selected-label');
  selected_label.find('circle').css("stroke-width", 0);

  if (selected_activity != "" && selected_product != ""){
    $('#grid_container_'+container_num).find("[activity_resource_val='"+ selected_activity +"_"+ selected_product +"']").addClass('selected-label');
    $('#grid_container_'+container_num).find("[activity_resource_val='"+ selected_activity +"_"+ selected_product +"'] circle").css("stroke-width", 2);}
}

function number_with_commas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

// basically parseFloat with certain precision
function show_decimals(number, precision) {
  return parseFloat(number).toFixed(precision);
}