function MapMarker (options, html) {
  var self = this;
  self.latlng = options.latlng;
  self.html      = marker_markup(options.selected, options.singleAreaMap);
  self.lookup    = options.lookup
  self.area_type = options.area_type
  self.country   = options.country
  self.display   = options.name
  self.color     = options.color
  self.singleAreaMap = options.singleAreaMap

  function marker_markup(selected, singleAreaMap){
    let html_class = 'azc-marker-container'
    if (selected){
      html_class += ' selected'
    }
    let cursor_class = null
    if (singleAreaMap === true) {
      cursor_class = ' marker-cursor-default'
    } else {
      cursor_class = ' marker-cursor-pointer'
    }
    return '<div class="' + html_class + '"> <div class="azc-marker ' + cursor_class + '"></div> <div class="label"> </div> </div>'
  }

  self.setMap(options.map);

  self.draw = function () {
    var point = self.getProjection().fromLatLngToDivPixel(self.latlng);

    if (typeof self.marker_html === 'undefined') {
      self.marker_html = $(self.html).clone().get(0);
      $(self.marker_html).find('.label').text(self.display);
      google.maps.event.addDomListener(self.marker_html, 'click', function () {
        google.maps.event.trigger(self, 'click');
      });
      google.maps.event.addDomListener(self.marker_html, 'mousemove', function(event) {
        google.maps.event.trigger(self, 'mousemove', event);
      });
      google.maps.event.addDomListener(self.marker_html, 'mouseout', function(event) {
        google.maps.event.trigger(self, 'mouseout', event);
      });
    }
    $(self.marker_html).css({
      top: point.y + 'px',
      left: point.x + 'px'
    });
    if (typeof(self.color) !== "undefined") {
      $(self.marker_html).find('.azc-marker').css({
        backgroundColor: self.color,
        color: getContrastYIQ(self.color)
      });
    }

    self.getPanes().overlayImage.appendChild(self.marker_html);
  }

  self.remove = function () {
    if (self.marker_html) {
      self.marker_html.parentNode.removeChild(self.marker_html);
      self.marker_html = null;
    }
    self.setMap(null);
  }

  self.getPosition = function () {
    return self.latlng;
  }

  function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.substr(1, hexcolor.length - 2);
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? '#555' : 'white';
  }
}

MapMarker.prototype = new google.maps.OverlayView();

export default MapMarker