import MapMarker from 'mapMarker'
import * as d3 from 'd3'

export default function GeoMap (selector, options) {
  var self      = this,
      container = $(selector)[0],
      areas     = {},
      polygons  = {},
      options   = (options || {}),
      focused_polygon, default_options, color_scale, map, overlay,
      min_count, max_count, map_controls;

  default_options = {
    singleAreaMap: false,
    scrollwheel: false,
    zoom: 9,
    maxZoom: 16,
    minZoom: 2,
    streetViewControl: false,
    disableDefaultUI: true,
    colors: ['#CFD8DC', '#B0BEC5', '#90A4AE', '#78909C', '#607D8B', '#546E7A', '#455A64', '#37474F'],
    disableDoubleClickZoom: true,
    polygon_counts: {},
    panControl: false,
    draggable: true,
    zoomControl: true,
    tooltip: "{{name}}",
    singleAreaMapBackgroundColor: '#F7FAFF', // this is for changing the "background color" of single area map
    // Map styles provided by Locus
    styles: [{
        "elementType": "geometry",
        "stylers": [{
          "color": "#f5f5f5"
        }]
      },
      {
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#b7b7b7"
        }]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#707070"
        }]
      },
      {
        "featureType": "administrative.land_parcel",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#bdbdbd"
          },
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [{
          "visibility": "on"
        }]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#b7b7b7"
        }]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "administrative.province",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#707070"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
          "color": "#eeeeee"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#757575"
        }]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{
          "color": "#eceeed"
        }]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#9e9e9e"
        }]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#e8edeb"
        }]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#757575"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{
          "color": "#dadada"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#616161"
        }]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#9e9e9e"
        }]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{
            "color": "#e5e5e5"
          },
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [{
          "color": "#eeeeee"
        }]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
          "color": "#c9c9c9"
        }]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#b0c4c7"
        }]
      },
      {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#9e9e9e"
        }]
      }
    ]
  }

  options = $.extend({}, default_options, options);

  (function initialize(){
    map = new google.maps.Map(container, options);
  })();

  function draw_map_controls () {
    var map_controls = $(container).parent().find(".map-controls"),
        container_number = $(container).parent('.area-container').data('container-number'),
        num_business_jobs_radio_btn_container = map_controls.find(".num-jobs-business").first('label[for=containers_'+container_number+'_business_jobs]').find('span'),
        legend = map_controls.find(".geomap-legend"),
        buckets_container = $('<div class="legend-buckets-container"><div/>'),
        bucket;

    // update the text depending on the barcode_type
    if (options.data_source == "Driver Economy") {
      num_business_jobs_radio_btn_container.text("Number of Large Businesses")
    }
    else if (options.data_source == "Jobs") {
      num_business_jobs_radio_btn_container.text("Number of Jobs")
    }
    else {
      num_business_jobs_radio_btn_container.text("Number of Businesses")
    }
    map_controls.find(".num-jobs-business").show();

    legend.find('.legend-buckets-container').remove();
    for (var i=0;i<options.colors.length;i++){
      bucket = $('<div/>').addClass('bucket').css({
        backgroundColor: options.colors[i]
      });

      buckets_container.append(bucket);
    }

    if (typeof min_count !== 'undefined' && typeof max_count !== 'undefined') {
      buckets_container.append("<span>"+min_count.toLocaleString()+"</span>");
      buckets_container.append("<span>"+max_count.toLocaleString()+"</span>");
    }

    legend.append(buckets_container);
  }

  function update_color_scale () {
    // Object.values is not supported in IE, but Object.keys does
    // So using key, to manually map to values array
    min_count = Math.min.apply(null, Object.keys(options.polygon_counts).map(function(key) {return options.polygon_counts[key]}));
    max_count = Math.max.apply(null, Object.keys(options.polygon_counts).map(function(key) {return options.polygon_counts[key]}));
    if (min_count == 0 && max_count == 0){
      // if domain numbers are not specified then returns the scale's current input domain which in this case is [0,1] and so that the map will be shaded with '#CFD8DC'
      color_scale = d3.scaleQuantize().range(options.colors) }
    else {
      color_scale = d3.scaleQuantize().domain([min_count, max_count]).range(options.colors)
    }
  }

  function create_polygon(polygon_coordinates, poly_options) {
    var lat, lng, coords = [];
    // for each polygon
    polygon_coordinates.forEach(function (linear_ring) {
      var sub_coords = [];
      // for each <LinearRing> in the polygon
      for (var i = 0; i < linear_ring.length; i++) {
        lat = linear_ring[i][1];
        lng = linear_ring[i][0];
        sub_coords.push(new google.maps.LatLng(lat, lng));
      }

      coords.push(sub_coords)
    });
    poly_options.paths = coords;
    let poly = new google.maps.Polygon(poly_options)
    if (poly.selected){
      focus_polygon(poly);
    }
    return poly;
  }

  function focus_polygon(polygon) {
    polygon.setOptions({ strokeWeight: 3})
  }

  function unfocus_polygon(polygon) {
    polygon.setOptions({ strokeWeight: polygon_stroke_weight(polygon)})
  }

  function polygon_stroke_weight(polygon) {
    if (polygon.area_type == 'Country') {
      return 2;
    } else {
      return 1;
    }
  }
  function polygon_options (area) {
    var domain = options.polygon_counts[area.lookup],
        in_domain = typeof domain !== 'undefined'

    return {
      strokeOpacity: .3,
      strokeWeight: polygon_stroke_weight(area),
      fillColor: in_domain ? color_scale(domain) : '#FFF',
      fillOpacity: in_domain ? 0.75 : 0.1,
      strokeColor: '#000000',
      name: area.name,
      lookup: area.lookup,
      area_type: area.area_type,
      count: area.count,
      country: area.country,
      selected: area.selected
    }
  }

  function zoom_to_area(polygon) {
    map.fitBounds(getBounds(polygon));
  }

  function getBounds(polygon) {
    var bounds = new google.maps.LatLngBounds(),
      paths,
      path;
    polygon = create_polygon(polygon, {})
    if (!polygon.concat) {
      // is not array
      polygon = [polygon];
    }

    for (var p = 0; p < polygon.length; p++) {
      paths = polygon[p].getPaths();
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
    }
    return bounds;
  }

  function add_polygon_listeners (polygon) {
    google.maps.event.addListener(polygon, "mousemove", function (event) {
      var name = '';

      if (!(polygon instanceof MapMarker)) {
        name = polygon.name
      }

      var tooltip = $('.tooltip'),
          projection = overlay.getProjection(),
          html       = options.tooltip.replace(/\{\{name\}\}/g, name);

      var position;
      if (!(event instanceof MouseEvent)) {
        position = projection.fromLatLngToContainerPixel(event.latLng);
        tooltip.html(html).css({
          // offset by header height and then by an extra 10 px to move it below
          // the cursor
          top: position.y + $(container).offset().top + 10,
          left: position.x + $(container).offset().left
        }).addClass('map');
      }

      if (html !== "") {
        tooltip.show();
      }
    });

    google.maps.event.addListener(polygon, 'mouseover', function () {
      if (!polygon.selected){
        focus_polygon(polygon);
      }
    })
    google.maps.event.addListener(polygon, "mouseout", function () {
      if (!polygon.selected){
        unfocus_polygon(polygon);
      }
      $('.tooltip').removeClass('map').hide();
    });

    google.maps.event.addListener(polygon, 'click', function () {
      polygon_click_listener(polygon)
    })
  }

  function polygon_click_listener(polygon){
    var form = $('.areas-index-form'),
    // check if compare mode is on. If this mode is on, polygon click should
    // add this polygon info to `container 1 form`
        container_number = $('.area-new').is(":visible") ? $('.area-new').data('container-number') : options.container_number,
        country_field = form.find('#containers_' + container_number + '_country'),
        area_type_field = form.find('#containers_' + container_number + '_area_type'),
        lookup_field = form.find('#containers_' + container_number + '_area'),
        new_country = polygon.country,
        new_area_type = polygon.area_type,
        current_country = country_field.val(),
        current_area_type =  area_type_field.val();
    if (current_country !== new_country) {
      form.find('#containers_' + container_number + '_hierarchy_id').val('')
    }
    country_field.val(new_country);
    lookup_field.val(polygon.lookup);
    area_type_field.val(new_area_type);

    form.submit();
  }

  function create_marker(area_info){
    let geometry = JSON.parse(area_info.geometry)
    let marker = new MapMarker({
              latlng: new google.maps.LatLng(geometry[0], geometry[1]),
              map: map,
              name: area_info.name,
              lookup: area_info.lookup,
              area_type: area_info.area_type,
              count: area_info.company_count,
              country: area_info.country,
              color: color_scale(options.polygon_counts[area_info.lookup]),
              selected: area_info.selected,
              singleAreaMap: options.singleAreaMap
            });
    return marker
  }

  this.set_overlay_and_draw_map_control = function() {
    overlay = new google.maps.OverlayView();
    overlay.setMap(map);
    draw_map_controls();
  }

  this.bold_marker = function (lookup) {
    for (polygon in polygons) {
      polygons[polygon].unbold
    }
    polygons[lookup].bold
  }

  this.polygons = function (areas) {
    self.remove_polygons();
    self.add_polygons(areas);
  }

  this.add_polygons = function (areas) {
    for (let area in areas) {
      let geometry = JSON.parse(areas[area].geometry)
      let poly = null
      if (areas[area].coords == true) {
        poly = create_marker(areas[area])
      } else {
        poly = create_polygon(geometry, polygon_options(areas[area]))
      }
      polygons[poly.lookup] = poly
      if (!options.singleAreaMap) {
        add_polygon_listeners(poly)
      } else {
        poly.setOptions({ clickable: false })
      }

      poly.setMap(map)
    }
  }

  this.remove_polygons = function () {
    for(let polygon in polygons){
        polygons[polygon].setMap(null)
    }
    polygons = {};
  }

  this.focus_area = function(polygon) {
    map.fitBounds(getBounds(polygon));
  }

  this.center_map = function(latitude, longitude, zoom){
    map.setCenter(new google.maps.LatLng(latitude, longitude))
    map.setZoom(zoom);
  }

  this.set_map_for_show_area = function() {
    map.draggable = false;
    map.zoomControl = false;
    options.styles.forEach(function(element) {
      if (["geometry.fill",
           "geometry.stroke",
           "geometry",
           "labels.text.fill",
           "labels.text.stroke" ].includes(element.elementType)) {
        element.stylers = []
        element.stylers.push({color: options.singleAreaMapBackgroundColor});
      }
    });
    map.setOptions({draggableCursor:'default', style: options.styles})
  }

  this.options = function (overrides) {
    if (typeof overrides === 'undefined') {
      return options;
    } else {
      $.extend(options, overrides);

      if (overrides.polygon_counts || overrides.colors) {
        update_color_scale();
      }

      draw_map_controls();
    }
  }

  // Pan map by position based on width of container.
  // Position is based on assume the map is at center already.
  // @param position [String] position of map at container,
  //  'right_center' or 'left_center'.
  this.pan_map_by_container = function (position) {
    // NOTE: x and y are 0 means the map is at the center of container.

    var width = $(container).width(),
        x = 0,
        y = 0;

    switch(position) {
      case 'right_center':
        x = -(width / 4);
        break;
      case 'left_center':
        x = (width / 4);
        break;
      default:
        // Return here, if none of position is valid param.
        return;
    }

    map.panBy(x, y);
  }
}
