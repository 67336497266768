$(document).on('activity_toggle', '#areas_show .activity-format-toggle', function () {
  var input = $('.areas-show-form #activity_count'),
      activity = $('.areas-show-form #activity'),
      resource = $('.areas-show-form #resource'),
      sector = $('.areas-show-form #sector');
  var new_value = input.val() == '4' ? '12' : '4';
  activity.val('');
  resource.val('');
  sector.val('');
  input.val(new_value);
  $('.areas-show-form').submit();
});

$(document).on('activity_toggle', '#areas_index .activity-format-toggle', function () {
  var container_number = $(this).closest('.area-container').data('container-number'),
      input = $('#containers_'+ container_number + '_activity_count'),
      new_value = input.val() == '4' ? '12' : '4',
      loc_quotient_activity_selector = $(".areas-index-form").find("#containers_"+container_number+"_location_quotient_activity");

  if (change_to_12(loc_quotient_activity_selector, new_value)){
    loc_val = loc_quotient_activity_selector.val() +'.2';
    loc_quotient_activity_selector.val(loc_val);
  }
  else if(change_to_4(loc_quotient_activity_selector, new_value)){
    loc_val = loc_quotient_activity_selector.val().split(".")[0];
    loc_quotient_activity_selector.val(loc_val);
  }

  input.val(new_value);
  $('.areas-index-form').submit();
});

function change_to_12(loc_quotient_activity_selector, activity_count) {
  var four_phase_activities = ['1' ,'2','3','4'];

  return four_phase_activities.includes(loc_quotient_activity_selector.val()) && activity_count == '12'
}

function change_to_4(loc_quotient_activity_selector, activity_count) {
  var twelve_phase_activities = ['1.1', '1.2', '1.3', '2.1', '2.2', '2.3', '3.1', '3.2', '3.3', '4.1', '4.2', '4.3'];
  return twelve_phase_activities.includes(loc_quotient_activity_selector.val()) && activity_count == '4'
}
