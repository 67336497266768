export function startProgressBar () {
  var progress_bar = new Turbolinks.ProgressBar();
  progress_bar.setValue(0);
  progress_bar.show();
  return progress_bar;
}

export function completeProgressBar (progress_bar) {
  progress_bar.setValue(1);
  progress_bar.hide();
}