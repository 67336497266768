$(document).on('activity_toggle', '#estimated_staffing_patterns_index', () => {
  let input = $('#activity_count')
  const activity_count = input.val() == 4 ? 12 : 4
  input.val(activity_count)
  $('#estimated_staffing_patterns_form').submit()
})

$(document).on('change', '#pivot_select', () => {
  $('#pivot').val($('#pivot_select').val())
  $('#estimated_staffing_patterns_form').submit()
})

$(document).on('change', '#industry_sector_select', () => {
  $('#fm_id').val($('#industry_sector_select').val())
  $('#estimated_staffing_patterns_form').submit()
})